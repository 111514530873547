.price {
  .page-header {
    .img {
      max-width: 88px;
    }
  }

  &__block {
    margin: 0;
    padding: 35px 15px;

    .inner {
      margin: 0 auto;
      padding: 0;
      width: 100%;
      max-width: 1100px;
    }

    &__ttl {
      font-size: 23px;
      font-weight: 700;
      text-align: center;
    }

    &__lead {
      margin-top: 30px;
    }

    ol {
      & > li {
        margin-left: 1.5em;
        list-style: decimal outside;
      }
    }
  }

  &__anchor {
    margin: -15px auto 10px;
  }

  &__cont {
    margin-top: 40px;

    &__ttl {
      font-size: 18px;
      text-align: center;
    }

    &__subTtl {
      margin-top: 22px;
      padding-left: 13px;
      color: #0072bc;
      font-size: 17px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1.05;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 3px;
        height: 15px;
        background-color: #0072bc;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__lead {
      margin-top: 20px;
    }

    &__name {
      margin-top: 28px;
      font-size: 18px;
      font-weight: 700;
      text-align: left;
    }

    table {
      margin-top: 25px;
    }

    .notice {
      margin-top: 38px;
    }
  }

  .p_anchor {
    margin: 70px auto;
    padding: 0 15px;
    width: 100%;
    max-width: 1130px;

    ul {
      display: flex;
      justify-content: center;
      margin: 0 -5px;
      flex-wrap: wrap;
    }

    li {
      padding: 0 35px;
      width: 50%;
    }
  }

  .price01, .price03{
    .rule, .application, .information {
      .title {
        background-color: #0072bc;
        margin: 0 -7px;
        color: #fff;
      }

      .flexBox {
        color: #fff;
        background-color: #0072bc;
        display: block;
        align-items: center;
        justify-content: center;
        margin: 0 -7px;
        margin-bottom: 60px;
      }

      .flexItem {
        padding: 20px;
        width: 100%;
      }

      .text {
        margin: 10px;
      }

      .p_btn{
        width: 100%;
        margin: 0;
      }
    }

    .application {
      .title {
        background-color: #0072bc;
        margin: 0 -7px;
        color: #fff;
      }

      .flexBox {
        color: #fff;
        background-color: #0072bc;
        display: block;
        align-items: center;
        justify-content: center;
        margin: 0 -7px;
        margin-bottom: 60px;
      }

      .flexItem {
        padding: 20px;
        width: 100%;
        height:auto;
      }

      .text {
        margin: 10px;
      }
    }

    .information {
      .title {
        background-color: #0072bc;
        margin: 0 -7px;
        color: #fff;
      }

      .flexBox {
        color: #fff;
        background-color: #0072bc;
        display: block;
        align-items: center;
        justify-content: center;
        margin: 0 -7px;
        margin-bottom: 60px;
      }

      .flexItem {
        padding: 20px;
        width: 100%;
      }

      .text {
        margin: 10px;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .price {
    .p_anchor {
      ul {
        flex-wrap: nowrap;
      }
    }

    .price01, .price03{
      .rule {  
        .flexBox {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
  
        .flexItem {
          width: 33.3333%;
        }
      }
  
      .application {
        .flexBox {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
  
        .flexItem {
          width: 33.3333%;
          height: 330px;
        }
      }
  
      .information {
        .flexBox {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
  
        .flexItem {
          width: 33.3333%;
          height: 300px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .price {
    .page-header {
      .img {
        max-width: 174px;
        right: 13px;
      }
    }

    &__block {
      padding: 75px 15px 87px;

      &__ttl {
        font-size: 35px;
      }

      &__lead {
        margin-top: 56px;
        text-align: left;
      }
    }

    &__anchor {
      margin: -15px auto 10px;
    }

    &__cont {
      margin-top: 75px;

      &__ttl {
        font-size: 24px;
        text-align: left;
        border-bottom: 1px solid #c0dbec;
        margin-bottom: 40px;
      }

      &__title {
        font-size: 20px;
        text-align: left;
        margin-bottom: 20px;
      }

      &__subTtl {
        margin-top: 44px;
        padding-left: 22px;
        font-size: 20px;
        letter-spacing: 0.05em;

        &::before {
          width: 7px;
          height: 24px;
        }
      }

      &__lead {
        margin-top: 34px;
        text-align: center;
      }

      &__name {
        margin-top: 0;
        font-size: 24px;
        text-align: left;
      }

      table {
        margin-top: 25px;
      }

      .notice {
        margin-top: 38px;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .intro {
    .leadTxt {
      text-align: center;
    }
  }
}

//----------------------------------
.price01 {
  padding: 15px 15px 55px;

  .cont_1 {
    .notice {
      margin-top: 38px;
    }
  }

  .cont_2 {
    margin-top: 50px;

    .notice {
      margin-top: 36px;

      ul {
        margin-top: 4px;
      }
    }
  }

  .cont_3 {
    margin-top: 48px;

    .price__cont__txt01 {
      margin-top: 10px;
    }

    .price__cont__txt02 {
      margin-top: 12px;
    }

    .price__cont__txt03 {
      margin-top: 12px;
      font-size: 13px;
    }
  }

  .cont_4 {
    margin-top: 48px;

    .price__cont__txt01 {
      margin-top: 18px;
    }
  }
}
@media screen and (min-width: 768px) {
  .price01 {
    table {
      &.tbl_1 {
        margin-top: 35px;

        thead {
          th {
            &:nth-of-type(1) {
              width: 24%;
            }
            &:nth-of-type(2) {
              width: 38%;
              font-size: 20px;
            }
            &:nth-of-type(3) {
              width: 38%;
              font-size: 20px;
            }
          }
        }
      }

      &.tbl_2 {
        margin-top: 35px;

        thead {
          th {
            &:nth-of-type(1) {
              width: 24%;
            }
            &:nth-of-type(2) {
              width: 38%;
              font-size: 20px;
            }
            &:nth-of-type(3) {
              width: 38%;
              font-size: 20px;
            }
          }
        }

        tbody {
          th,
          td {
            height: 70px;
          }
        }
      }
    }

    .cont_1 {
      margin: 75px auto 0;
      max-width: 900px;

      .notice {
        margin-top: 51px;
      }
    }

    .cont_2 {
      margin: 96px auto 0;
      max-width: 900px;

      .notice {
        margin-top: 56px;

        ul {
          margin-top: 13px;
        }
      }
    }

    .cont_3 {
      margin: 95px auto 0;
      max-width: 900px;

      .price__cont__txt01 {
        margin-top: 17px;
      }

      .price__cont__txt02 {
        margin-top: 17px;
      }

      .price__cont__txt03 {
        margin-top: 16px;
      }
    }

    .cont_4 {
      margin: 91px auto 0;
      max-width: 900px;

      .price__cont__txt01 {
        margin-top: 33px;
        text-align: center;
      }
    }
  }
}

//----------------------------------
.price02 {
  padding: 64px 15px 50px;

  .table_title {
    display: flex;
    justify-content: space-between;
  }

  ul {
    margin-bottom: 40px;
  }

  li {
    list-style: inside;
  }

  table {
    margin-bottom: 40px;

    &.tbl_1 {
      margin-top: 25px;

      thead {
        th {
          padding: 15px 0;
        }
        th.blue {
          background-color: #0072bc;
        }
      }

      tbody {
        th.blue {
          background-color: #f1f6f9;
          border-right: 1px solid #c0dbec;
          width: 30%;
          padding: 10px;
          text-align: left;
        }

        td {
          text-align: left;
          padding: 10px;
        }
      }
    }

    &.tbl_2 {
      margin-top: 22px;

      tbody {
        th,
        td {
          height: 59px;
        }

        th {
          background-color: #f1f6f9;
        }
      }
    }

    &.tbl_3 {
      margin-top: 22px;
    }

    &.tbl_4 {
      margin-top: 22px;
    }
  }

  table {
    &.tbl_3 {
      margin-top: 22px;
    }
  }

  .cont_1 {
    margin-top: 28px;

    .price__cont__feature {
      margin-top: 15px;
    }

    .feature__list {
      & + .feature__list {
        margin-top: 20px;
      }
    }
  }

  .cont_2 {
    margin-top: 36px;
  }

  .cont_3 {
    margin-top: 34px;

    .notice {
      margin-top: 34px;
    }
  }
}

.price04 {
  padding: 64px 15px 50px;

  li {
    list-style: inside;
  }

  table {
    margin-bottom: 40px;

    &.tbl_1 {
      margin-top: 25px;

      thead {
        th {
          padding: 15px 0;
        }
        th.blue {
          background-color: #0072bc;
        }
      }

      tbody {
        th.blue {
          background-color: #f1f6f9;
          border-right: 1px solid #c0dbec;
          width: 30%;
          padding: 5px;
          text-align: left;
        }

        td {
          text-align: left;
        }
      }
    }

    &.tbl_2 {
      margin-top: 22px;

      tbody {
        th,
        td {
          height: 59px;
        }

        th {
          background-color: #f1f6f9;
        }
      }
    }

    &.tbl_3 {
      margin-top: 22px;
    }

    &.tbl_4 {
      margin-top: 22px;
    }
  }

  table {
    &.tbl_3 {
      margin-top: 22px;
    }
  }

  .cont_1 {
    margin-top: 28px;

    .price__cont__feature {
      margin-top: 15px;
    }

    .feature__list {
      & + .feature__list {
        margin-top: 20px;
      }
    }
  }

  .cont_2 {
    margin-top: 36px;
  }

  .cont_3 {
    margin-top: 34px;

    .notice {
      margin-top: 34px;
    }
  }
}
@media screen and (min-width: 768px) {
  .price02 {
    padding: 15px;

    table {
      &.tbl_3 {
        margin-top: 35px;

        thead {
          th {
            &:nth-of-type(1) {
              width: 18%;
            }
            &:nth-of-type(2) {
              width: 37%;
              font-size: 20px;
            }
            &:nth-of-type(3) {
              width: 45%;
              font-size: 20px;
            }
          }
        }
      }
    }

    .cont_1 {
      margin: 60px auto 0;

      .flexBox {
        display: flex;
        justify-content: space-between;
      }

      .flexItem {
        &.img {
          width: 41%;
        }
        &.textarea {
          width: 52%;
        }
      }

      .price__cont__feature {
        margin-top: 50px;
      }
    }

    .cont_2 {
      margin: 56px auto 0;
      max-width: 900px;

      .price__cont__txt01 {
        text-align: center;
      }
    }

    .cont_3 {
      margin: 80px auto 0;
      max-width: 900px;
    }
  }
}

//----------------------------------
.price03 {
  padding: 15px 15px 50px;

  .rule {
    .title {
      background-color: #0072bc;
      margin: 0 -7px;
      color: #fff;
    }

    .flexBox {
      color: #fff;
      background-color: #0072bc;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 -7px;
      margin-bottom: 60px;
    }

    .flexItem {
      padding: 20px;
      width: 33.3333%;
    }

    .text {
      margin: 10px;
    }
  }

  table {
    &.tbl_4 {
      margin-top: 22px;

      thead {
        th {
          height: 52px;
        }
      }
    }
  }

  .cont_1 {
    margin-top: 30px;

    .price__cont__txt01 {
      margin-top: 30px;
    }

    .note {
      margin-top: 20px;
    }
  }
}
@media screen and (min-width: 768px) {
  .price03 {
    padding: 15px;

    table {
      &.tbl_4 {
        margin-top: 32px;

        thead {
          th {
            height: 72px;

            &:nth-of-type(1) {
              width: 50%;
              font-size: 20px;
            }
            &:nth-of-type(2) {
              width: 50%;
              font-size: 20px;
            }
          }
        }
        tbody {
          th {
            height: 72px;
          }
        }
      }
    }

    .cont_1 {
      margin: 0 auto 0;
      max-width: 900px;

      .price__cont__txt01 {
        margin-top: 56px;
        text-align: center;
      }

      .note {
        margin-top: 20px;
      }
    }
  }
}
